import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import emailImg from "../resources/email.png";
import questionImg from "../resources/question.png";
import rfeImg from "../resources/rfe.png";
import listImg from "../resources/list.png";
import arrowImg from "../resources/arrow.png";
import circleImg from "../resources/circle.png";

class Documentation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="tab-div">
                <p className="list-heading">Learning</p>
                <ul className="list-css1">
                    <li className="list-item-image-css">
                        <img src={circleImg} className="list-bullet-css"/><a href="https://mit-satori.github.io/" target="_blank">MIT Satori User Documentation</a>
                    </li>
                </ul>
                <p className="list-heading">Additional Old Packages Locations</p>
                <ul className="list-css1">
                    <li className="list-item-image-css">
                        <img src={circleImg} className="list-bullet-css"/><a href="https://hub.docker.com/r/ibmcom/powerai" target="_blank">WMLCE containers</a>
                    </li>
                    <li className="list-item-image-css">
                        <img src={circleImg} className="list-bullet-css"/><a href="https://public.dhe.ibm.com/ibmdl/export/pub/software/server/ibm-ai/conda-early-access/" target="_blank">WMLCE early access channel</a>
                    </li>
                    <li className="list-item-image-css">
                        <img src={circleImg} className="list-bullet-css"/><a href="https://anaconda.org/powerai" target="_blank">Supplementary channel</a>
                    </li>
                </ul>
                <p className="list-heading">Contact Us</p>
                <ul className="list-css1">
                    <li className="list-item-image-css">
                        <img src={emailImg} className="list-image-css"/>Email:&nbsp;<a href="mailto:satori-support@techsquare.com" target="_blank">satori-support@techsquare.com</a>
                    </li>
                </ul>
            </div>
        );
    }
}

export default withRouter(Documentation);


