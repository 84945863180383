import React, { Component } from "react";
import {
	Route,
	NavLink,
	HashRouter
} from "react-router-dom";
import PackagesTable from "./components/packages_datatable";
import ChangeLog from "./components/change_log";
import Documentation from "./components/documentation";

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			packagesFlag : true,
			documentationFlag : false
		}
	}

	handleOnClick = (evt) => {
		if(evt.target.id == "packages"){
			this.setState({
				packagesFlag : true, 
				documentationFlag : false
			});
		}
		else {
			this.setState({
				packagesFlag : false,
				documentationFlag : true
			});
		}
		
	}

	getClassName = (value) => {
		let className = "bx--tabs__nav-item",
			tempList = window.location.href.split("#"),
			location = tempList[tempList.length - 1];
		
		console.log("Location : " + location);
		if(location.includes(value)){
			className = "bx--tabs__nav-item--selected";
		}
		else if((location === "/" || location.includes("http")) && value === "packages") {
			className = "bx--tabs__nav-item--selected";
		}

		return className;
	}

	render() {
		return (
			<HashRouter>
				{/* <div class="bx--tabs" role="navigation" id="tabs_container">
					<div role="listbox" class="bx--tabs-trigger"> */}
					<div>
						<ul role="tablist" class="bx--tabs__nav bx--tabs__nav--hidden">
							<li value = "packages" role="presentation" className={this.getClassName("packages")} onClick={this.handleOnClick}>
								<NavLink id = "packages" className="bx--tabs__nav-link" exact to="/">Packages</NavLink>
							</li>
							<li value = "documentation" role="presentation" className={this.getClassName("documentation")} onClick={this.handleOnClick}>
								<NavLink id = "documentation" className="bx--tabs__nav-link" to="/documentation">Documentation</NavLink>
							</li>
						</ul>
						<div className="content">
							<Route exact path="/" component={PackagesTable} />
							<Route path="/changelog" component={ChangeLog} />
							<Route path="/documentation" component={Documentation} />
						</div>
					{/* </div> */}
				</div>
			</HashRouter>
		);
	}
}

export default Main;
