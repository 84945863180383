import React, { Component } from "react";
import { DataTable, Search } from 'carbon-components-react';
import allPackagesList from "../data/all_packages_list.json";
import { withRouter } from 'react-router-dom';
import filterImg from "../resources/filter.png";
import downloadImg from "../resources/download.png";

const {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TableHeader,
    TableExpandRow,
    TableExpandedRow,
    TableExpandHeader
} = DataTable,
headers = [
    {
        key: 'name',
        header: 'Name',
    },
    {
        key: 'version',
        header: 'Version',
    },
    {
        key: 'platform',
        header: 'Platform',
    },
    {
        key: 'summary',
        header: 'Summary',
    },
]; 

class PackagesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: this.props.rows,
            filteredRows: this.props.rows,
            timestamp: "",
            expList: {},
            packagesData: allPackagesList["packages_data"],
        };
    }

    static defaultProps = {
        rows: []
    };

    componentDidMount() {
        this.loadData();
    }

    linkMouseOut = (evt) => {
        const imgElement = document.getElementById(evt.target.id+"-img");
        imgElement.className = "li-img";
    }

    linkMouseOver = (evt) => {
        const imgElement = document.getElementById(evt.target.id+"-img");
        imgElement.className = "li-img--hover";
    }

    loadData(searchString="") {
        let packagesList = [],
            exp_package = {},
            timestamp = "";
        const packagesData = this.state.packagesData
        
        const downloadUrl = allPackagesList["packages_download_url"]
        
        for (let i = 0; i < packagesData.length; i++) {
            let packageMap = {}
            if (searchString === "" || packagesData[i]["name"].toLowerCase().includes(searchString.toLowerCase())) {
                
                packageMap["id"] = packagesData[i]["id"]
                packageMap["name"] = packagesData[i]["name"]
                packageMap["version"] = packagesData[i]["version"]
                packageMap["platform"] = packagesData[i]["platform"]
                
                // packageMap["platform"] = platformData
                packageMap["summary"] = packagesData[i]["summary"]
                packagesList.push(packageMap)

                let platforms = packagesData[i]["platform"].split(",");
                let platformData = []
                for (let j = 0; j < platforms.length; j++) {
                    const platform = platforms[j].trim(),
                        builds = packagesData[i]["builds"][platform];

                    let dl_links = null,
                        listItem = [];
                    
                    builds.forEach(build => {
                        const packageName = packagesData[i]["name"] + "-" + build + ".tar.bz2",
                            completeGsaURL = downloadUrl + platform + "/" + packageName,
                            elementId = platform + "-" + packageName;
                        listItem.push(<div><a href={completeGsaURL} target="_blank"><img id={elementId + "-img"} src={downloadImg} className="li-img"/></a><a id={elementId} className="li-link" href={completeGsaURL} onMouseOut={this.linkMouseOut} onMouseOver={this.linkMouseOver}>{packageName}</a></div>)
                    });

                    console.log(listItem);

                    dl_links = <ul><li className="table-list-item-css">{platform}<ul>{listItem.map(t => 
                        <li className="table-list-item-css-level2">{t}</li> 
                    )}</ul></li></ul>
                    platformData.push(dl_links)
                    
                }
                exp_package[packagesData[i]["id"]] = platformData;
            }
        }

        this.setState({
            rows: packagesList,
            filteredRows: packagesList,
            expList: exp_package,
            timestamp: timestamp,
            packagesData: packagesData
        })
    }

    filterRows = (_ref) => {
        var rowIds = _ref.rowIds,
            headers = _ref.headers,
            cellsById = _ref.cellsById,
            inputValue = _ref.inputValue;

        let newRows = rowIds.filter(function (rowId) {
            return headers.some(function (_ref2) {
                var key = _ref2.key;
                var id = "".concat(rowId, ":").concat(key);;
                if (typeof cellsById[id].value === 'boolean') return false;
                return ('' + cellsById[id].value).toLowerCase().includes(inputValue.toLowerCase());
            });
        });

        return newRows;
    }

    onTextChange = (evt) => {
        this.loadData(evt.target.value);
    }

    customSortRow = (cellA, cellB, { sortDirection, sortStates, locale }) => {
        if (sortDirection === sortStates.DESC) {
          return cellB.localeCompare(cellA);
        }
      
        return cellA.localeCompare(cellB);
    };

    render() {
        const searchProps = () => ({
            placeHolderText: "Search",
            onChange: this.onTextChange,
        });  

        return (
            <div className="tab-div">
                <fieldset className="fieldset1">
                    <legend className="legend1">
                        <img src={filterImg} className="filter-img"></img>
                        <p className="labels" style={{ marginLeft: "4px" }}><strong>Filters</strong></p>
                    </legend>
                    <div className="filter-elements">
                        <p className="labels"><strong>Search :</strong></p>
                        <div>
                            <Search {...searchProps()} id="searchBox" name="searchBox" />
                        </div>
                    </div>
                </fieldset>
                <br />
                <br />
                <DataTable
                    rows={this.state.rows}
                    headers={headers}
                    filterRows={this.filterRows}
                    sortRow={this.customSortRow}
                    isSortable={true}
                    render={({ rows, headers, getHeaderProps, filterRows, sortRow, getRowProps, getTableProps }) => (
                        <TableContainer>
                            <Table className="bx--data-table bx--data-table--zebra">
                                <TableHead>
                                    <TableRow>
                                        <TableExpandHeader />
                                        {headers.map(header => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => (
                                        <React.Fragment key={row.id}>
                                            <TableExpandRow {...getRowProps({ row })}>
                                                {row.cells.map(cell => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                            </TableExpandRow>
                                            {row.isExpanded && (
                                                <TableExpandedRow colSpan={headers.length + 1}>
                                                    {this.state.expList[row.cells[0].value+row.cells[1].value].map((dep, i) => <div style={{marginLeft: "30px"}}>{dep}</div>)}
                                                </TableExpandedRow>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                />
            </div>
        );
    }
}

export default withRouter(PackagesTable);