import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ChangeLog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="tab-div">
                <p className="list-heading">What's new in 1.7.0</p>
                <ul className="list-css">
                    <li className="list-item-css">
                        Support for the IBM® Power® Systems IC922 and the NVIDIA T4 Tensor Core GPU
                    </li>
                    <li className="list-item-css">
                        CUDA 10.2 Support
                    </li>
                    <li className="list-item-css">
                        TensorFlow 2.1 with eager execution and the redesigned APIs for TensorFlow 2
                    </li>
                    <li className="list-item-css">
                        Technology previews include - 
                        <ul>
                            <li className="list-item-css-level2">
                                pai4sk: SnapBoost algorithm
                            </li>
                            <li className="list-item-css-level2">
                                Dask support for GPU-backed dataframe and multi-GPU machine learning algorithms
                            </li>
                            <li className="list-item-css-level2">
                                CuPy: NumPy-like API accelerated with CUDA
                            </li>
                            <li className="list-item-css-level2">
                                LMS for TensorFlow 2. See <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.7.0/navigation/wmlce_getstarted_tflms.html"> Getting started with TensorFlow large model support</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p className="list-heading">What's new in 1.6.2</p>
                <ul className="list-css">
                    <li className="list-item-css">
                        Improved performance for Distributed Deep Learning on large scale jobs.
                    </li>
                    <li className="list-item-css">
                        NVIDIA TensorRT is included. See: <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_tensorrt.html"> Getting started with PyTorch and TensorRT</a>.
                    </li>
                    <li className="list-item-css">
                        Apex is no longer a technology preview and is fully supported. See: <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_apex.html"> PyTorch: NVIDIA APEX Automatic Mixed Precision and optimizer support.</a>
                    </li>
                    <li className="list-item-css">
                        Torchtext is no longer a technology previews and is fully supported. See: <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_torchtext.html"> PyTorch: torchtext and pytext natural language support.</a>
                    </li>
                    <li className="list-item-css">
                        The <a target="_blank" href="https://www.tensorflow.org/xla"> XLA (Accelerated Linear Algebra) compiler</a> has been enabled in Tensorflow. See <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_tensorflow.html"> Getting started with TensorFlow</a>.
                    </li>
                    <li className="list-item-css">
                        SnapML Estimators and Transformer are introduced and can be used in a <a target="_blank" href="https://spark.apache.org/docs/latest/ml-pipeline.html">Spark ML pipeline</a>. See: <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_snapmlspark.html"> Getting started with Snap Machine Learning (SnapML) on Apache Spark.</a>
                    </li>
                    <li className="list-item-css">
                        The CPU-only package for Pytorch is now supported. See <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_pytorch.html">Getting started with PyTorch</a>.
                    </li>
                    <li className="list-item-css">
                        Python 3.7 support.
                    </li>
                    <li className="list-item-css">
                        Technology previews include - 
                        <ul>
                            <li className="list-item-css-level2">
                                pai4sk: SnapBoost algorithm - see <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_snapml.html"> Getting started with IBM Distributed Accelerated ML library.</a>
                            </li>
                            <li className="list-item-css-level2">
                                Dask support for GPU-backed dataframe and multi-GPU machine learning algorithms - see <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_rapids.html"> cuML</a>
                            </li>
                            <li className="list-item-css-level2">
                                Dask-XGBoost - see <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_xgboost.html">Getting started with Dask-XGBoost</a>
                            </li>
                            <li className="list-item-css-level2">
                                CuPy: NumPy-like API accelerated with CUDA - see cuPy in <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.2/navigation/wmlce_getstarted_rapids.html"> Getting started with Rapids</a>
                            </li>
                        </ul>
                    </li>
                    <li className="list-item-css">
                        Deprecations - 
                        <ul>
                            <li className="list-item-css-level2">
                                Anaconda 2 is not supported.
                            </li>
                            <li className="list-item-css-level2">
                                Python 2.x is not supported.
                            </li>
                            <li className="list-item-css-level2">
                                The Pytext technology preview is no longer available through WML CE.
                            </li>
                        </ul>
                    </li>
                    
                </ul>
                <p className="list-heading">What's new in 1.6.1</p>
                <ul className="list-css">
                    <li className="list-item-css">
                        <a target="_blank" href="https://www.tensorflow.org/tfx/serving/architecture">TensorFlow Serving and TensorFlow Serving API</a> 1.14 are now included.
                    </li>
                    <li className="list-item-css">
                        <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_keras.html">Keras-team Keras</a> 2.2.4 is now included.
                    </li>
                    <li className="list-item-css">
                        <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_DALI.html">NVIDIA DALI 0.9.0</a> is now included.
                    </li>
                    <li className="list-item-css">
                        CPU-only packages for TensorFlow and IBM-enhanced Caffe.
                    </li>
                    <li className="list-item-css">
                        Support for WML CE on x86.
                    </li>
                    <li className="list-item-css">
                        Technology previews include - 
                        <ul>
                            <li className="list-item-css-level2">
                                <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_tensorrt.html">NVIDIA TensorRT</a>
                            </li>
                            <li className="list-item-css-level2">
                                <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_pytext.html">PyTorch: torchtext and pytext natural language support</a>
                            </li>
                            <li className="list-item-css-level2">
                                <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_apex.html">PyTorch: NVIDIA APEX Automatic Mixed Precision and optimizer support</a>
                            </li>
                            <li className="list-item-css-level2">
                                <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_tensorflow.html$wmlce_getstarted_tensorflow__tf2techpreview">TensorFlow 2.0</a>
                            </li>
                            <li className="list-item-css-level2">
                                <a target="_blank" href="https://www.ibm.com/support/knowledgecenter/SS5SF7_1.6.1/navigation/wmlce_getstarted_snapmlspark.html">Snap ML: snap-ml-spark library</a>
                            </li>
                        </ul>
                    </li>
                    
                </ul>
                <p className="list-heading">What's new in 1.6.0</p>
                <ul className="list-css">
                    <li className="list-item-css">
                        pai4sk MPI based distribution of "data-loading, training, prediction and metrics" for snapML solvers
                    </li>
                    <li className="list-item-css">
                        cuDF data-frames for snapML solvers in local mode
                    </li>
                    <li className="list-item-css">
                        Accelerated ML algorithms from cuML
                    </li>
                    <li className="list-item-css">
                        DD has Improved support for LSF
                    </li>
                    <li className="list-item-css">
                        DDL now supports  TensorFlow Eager Mode
                    </li>
                    <li className="list-item-css">
                        Added TF estimator 1.13.0
                    </li>
                    <li className="list-item-css">
                        Technology previews include - 
                        <ul>
                            <li className="list-item-css-level2">
                                TensorFlow Large Model Support (TFLMS) V1
                            </li>
                            <li className="list-item-css-level2">
                                Snap ML: snap-ml-spark library
                            </li>
                            <li className="list-item-css-level2">
                                pai4sk: Decision Tree and Random Forest algorithms from SnapML
                            </li>
                            <li className="list-item-css-level2">
                                RAPIDS.AI: cuML and cuDF
                            </li>
                        </ul>
                    </li>
                    
                    
                </ul>
            </div>
        );
    }
}

export default withRouter(ChangeLog);

